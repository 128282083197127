import SvgClose from '@/components/icons/svg/close'
import { Link } from '@/components/link/link'
import { CategoryFilterOption } from '@/modules/category/category-types'
import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'

type Props = {
  filterEventId?: string | null
  option?: CategoryFilterOption | null
  isInFilterDropdown?: boolean
}

const CategoryFiltersMultiselectLabel = ({
  filterEventId,
  option,
  isInFilterDropdown = true,
}: Props) => {
  const { onFilterClick } = useCategoryFilters()

  if (!option?.url) {
    return null
  }

  if (isInFilterDropdown) {
    return (
      <Link
        href={option?.url}
        aria-selected={option?.used ?? false}
        className="px-2 py-1 m-1 text-white aria-selected:text-white bg-primary aria-selected:bg-secondary font-semibold h-fit"
        scroll={false}
        onClick={() => onFilterClick(filterEventId)}
      >
        {option?.name} ({option?.count})
      </Link>
    )
  }

  return (
    <div className="flex relative py-1 pl-2 text-white bg-primary font-semibold h-fit">
      {option?.name} ({option?.count})
      <Link
        className="flex justify-center ml-2 items-center pr-2"
        href={option?.url}
        scroll={false}
        onClick={() => onFilterClick(filterEventId)}
      >
        <SvgClose fill="white" fontSize={8} />
      </Link>
    </div>
  )
}

export default CategoryFiltersMultiselectLabel
