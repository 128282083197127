'use client'

import { useCallback, useState } from 'react'
import { useTranslations } from 'next-intl'

import {
  categoryFullDescriptionId,
  categoryTitleId,
} from './category-description-constants'

export function CategoryDescriptionButton(): JSX.Element {
  const t = useTranslations('Category')
  const [descriptionExpanded, setDescriptionExpanded] = useState(false)

  const scrollToHeading = useCallback(() => {
    document
      .getElementById(categoryTitleId)
      ?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const toggleFullDescriptionVisibility = useCallback(() => {
    if (descriptionExpanded) {
      document
        .getElementById(categoryFullDescriptionId)
        ?.classList.remove('inline')
      document
        .getElementById(categoryFullDescriptionId)
        ?.classList.add('hidden')
      setDescriptionExpanded(false)
      scrollToHeading()
    } else {
      document
        .getElementById(categoryFullDescriptionId)
        ?.classList.remove('hidden')
      document
        .getElementById(categoryFullDescriptionId)
        ?.classList.add('inline')
      setDescriptionExpanded(true)
      scrollToHeading()
    }
  }, [descriptionExpanded, scrollToHeading])

  return (
    <button
      className="text-secondary"
      onClick={toggleFullDescriptionVisibility}
    >
      {descriptionExpanded
        ? t('description.showLess')
        : t('description.showMore')}
    </button>
  )
}
