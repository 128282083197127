import { useCallback } from 'react'

import {
  AvailableCategorySearchFilters,
  CategoryFilter,
} from '@/modules/category/category-types'
import CategoryFiltersMultiselectOthers from './category-filters-multiselect-others'
import CategoryFiltersMultiselectLabel from './category-filters-multiselect-label'

type Props = {
  availableFilters: AvailableCategorySearchFilters
}

export const CategoryFiltersMultiselect = ({ availableFilters }: Props) => {
  const multiselectFilters = availableFilters?.filter(
    (filter) => filter?.type === 'multiselect',
  )

  // Note: Top 5 and selected options
  const getVisibleOptions = useCallback((filter: CategoryFilter | null) => {
    const options = (filter?.options ?? [])?.sort(
      (a, b) => (b?.count ?? 0) - (a?.count ?? 0),
    )
    const top5 = options.slice(0, 5)
    const rest = options.slice(5, options.length)
    const result = [...top5, ...rest.filter((option) => option?.used)]

    return result
  }, [])

  // Note: Not top 5 and not selected options
  const getHiddenOptions = useCallback((filter: CategoryFilter | null) => {
    const options = (filter?.options ?? [])?.sort(
      (a, b) => (b?.count ?? 0) - (a?.count ?? 0),
    )
    const rest = options.slice(5, options.length)
    const result = [...rest.filter((option) => !option?.used)]

    return result
  }, [])

  return (
    <>
      {multiselectFilters?.map((filter, index) => (
        <div
          key={`multiselect-${filter?.name}-${index}`}
          className="border-b border-black py-4"
        >
          <div className="flex flex-col md:flex-row w-full pb-2">
            <div className="basis-3/12 flex flex-row justify-between items-center md:justify-normal md:items-start pb-2 md:pl-2 md:pb-0">
              <h3 className="font-bold">{filter?.name?.toUpperCase()}</h3>
            </div>
            <div className="basis-9/12 w-full flex flex-row flex-wrap -m-1">
              {getVisibleOptions(filter)?.map((option, index) => (
                <CategoryFiltersMultiselectLabel
                  key={`${filter?.name}-${option?.name}-${index}`}
                  option={option}
                  filterEventId={filter?.global_name}
                />
              ))}
            </div>
          </div>
          {getHiddenOptions(filter)?.length > 0 && (
            <CategoryFiltersMultiselectOthers
              filter={filter}
              availableFilterOptions={getHiddenOptions(filter)}
            />
          )}
        </div>
      ))}
    </>
  )
}
