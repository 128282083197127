// import { SEARCH_PARAMS } from '@/common/constants/url-constants'
import { StoreCodeType } from '@/common/types'
import { getPathnameWithoutLocale } from '@/common/utils/store.exceptions'
// import { SortingValues } from './parts/filters/filters.const'

/**
 * Note: keep only "ttok" and "product_list_order=reviews_count" filters as part of filters
 */
// const getCategoryIdFilters = (search?: string): string => {
//   if (!search) {
//     return ''
//   }

//   const searchParams = new URLSearchParams(search)
//   const hasTikTokParam = searchParams.has(SEARCH_PARAMS.TikTok)
//   const hasSortingParam = searchParams.has(SEARCH_PARAMS.CategorySorting)

//   let filters: string[] = []

//   if (hasTikTokParam) {
//     filters.push(SEARCH_PARAMS.TikTok)
//   }

//   if (hasSortingParam) {
//     const isReviewsCountSortingParam =
//       searchParams.get(SEARCH_PARAMS.CategorySorting) ===
//       SortingValues.ReviewsCount

//     if (isReviewsCountSortingParam) {
//       filters.push(SEARCH_PARAMS.CategorySorting)
//     }
//   }

//   const paramsString = filters
//     .map((param) => `${param}=${searchParams.get(param)}`)
//     .join('&')

//   if (paramsString) {
//     return `?${paramsString}`
//   }

//   return ''
// }

export const getCategoryId = ({
  pathname,
  storeCode,
}: {
  pathname?: string
  search?: string
  storeCode: StoreCodeType
}) => {
  if (!pathname) {
    return ''
  }

  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname, storeCode)

  return pathnameWithoutLocale.split('/')[1]
}
