'use client'

import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import { twJoin } from 'tailwind-merge'

import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { getURLObjectSafely } from '@/common/utils/url-utils'

export type CategoryFiltersHookResponse = {
  isShowingFilters: boolean
  areFiltersLoading: boolean
  onFilterClick: (filterId?: string | null) => void
  onToggleFilters: () => void
  onFiltersLoaded: () => void
  startFiltersLoading: () => void
}

const CategoryFiltersContext = createContext<
  CategoryFiltersHookResponse | undefined
>(undefined)

export const useCategoryFilters = () => {
  const context = useContext(CategoryFiltersContext)
  if (context === undefined) {
    throw new Error(
      'useCategoryFilters must be used within a CategoryFiltersProvider',
    )
  }
  return context
}

export const CategoryFiltersProvider = ({
  children,
  href,
  categoryId,
}: PropsWithChildren<{ href: string; categoryId: string }>) => {
  const url = getURLObjectSafely(href)
  const showFilters = `/${categoryId}` !== `${url.pathname}${url.search}`
  const [areFiltersLoading, setAreFiltersLoading] = useState(false)
  const [isShowingFilters, setIsShowingFilters] = useState(showFilters)

  const startFiltersLoading = () => {
    setAreFiltersLoading(true)
  }

  const onFilterClick = useCallback((filterId?: string | null) => {
    if (filterId) {
      gtmTracker.trackFilter(filterId)
    }

    startFiltersLoading()
  }, [])

  const onToggleFilters = useCallback(() => {
    if (!isShowingFilters) {
      gtmTracker.trackFiltersShown()
    } else {
      gtmTracker.trackFiltersHidden()
    }

    setIsShowingFilters((prev) => !prev)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingFilters])

  const onFiltersLoaded = useCallback(() => {
    setAreFiltersLoading(false)
  }, [])

  return (
    <CategoryFiltersContext.Provider
      value={{
        isShowingFilters,
        areFiltersLoading,
        onFilterClick,
        onToggleFilters,
        onFiltersLoaded,
        startFiltersLoading,
      }}
    >
      <div className="relative">
        {areFiltersLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-95 z-20">
            <div
              className={twJoin(
                'absolute inset-0 mx-auto mt-32',
                'h-16 w-16',
                'rounded-full',
                'animate-spin',
                'border-transparent border-[3px] border-t-black z-40',
              )}
            />
          </div>
        )}
        {children}
      </div>
    </CategoryFiltersContext.Provider>
  )
}
