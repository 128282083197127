import React from 'react'
import { useTranslations } from 'next-intl'

import { Link } from '@/components/link/link'
import { AvailableCategorySearchFilters } from '../../category-types'
import SvgClose from '@/components/icons/svg/close'
import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'
import { getActiveFilters } from '@/common/utils'
import CategoryFiltersMultiselectLabel from './category-filters-multiselect/category-filters-multiselect-label'
import { Currency } from '@/components/currency'
import { getURLObjectSafely } from '@/common/utils/url-utils'
import { getCategoryId } from '../../category.utils'
import { useStoreContext } from '@/providers'

type SelectedCategoryFiltersProps = {
  filters: AvailableCategorySearchFilters
  href: string
}

export default function SelectedCategoryFilters({
  filters,
  href,
}: SelectedCategoryFiltersProps) {
  const t = useTranslations('Category')
  const { startFiltersLoading } = useCategoryFilters()
  const range = filters?.find((filter) => filter?.type === 'range')?.range
  const url = getURLObjectSafely(href)
  const { storeCode } = useStoreContext()

  const initialPriceValues = url.searchParams.get('price')
    ? [
        parseFloat(
          url.searchParams.get('price')?.split('-')[0] ?? `${range?.max}`,
        ),
        parseFloat(
          url.searchParams.get('price')?.split('-')[1] ?? `${range?.min}`,
        ),
      ]
    : null

  const selectedFilters = getActiveFilters(filters)

  const isRangeFilter = initialPriceValues && range
  const areFiltersPresent =
    isRangeFilter || (selectedFilters && selectedFilters.length > 0)

  const clearRangeFilterUrl = () => {
    const newUrl = new URL(url)

    newUrl.searchParams.delete('price')

    return newUrl.toString()
  }

  return (
    <div className="flex flex-wrap mt-5 mb-8 justify-end items-center gap-2">
      {selectedFilters?.map((option) => (
        <CategoryFiltersMultiselectLabel
          key={`selected-filter-${option?.url}`}
          option={option}
          filterEventId={option?.filterEventId}
          isInFilterDropdown={false}
        />
      ))}

      {isRangeFilter && (
        <div className="flex items-center justify-center pl-2 py-1 text-white relative bg-primary font-semibold h-fit">
          <Currency
            className="text-white font-semibold"
            price={initialPriceValues[0]}
          />
          &nbsp;-&nbsp;
          <Currency
            className="text-white font-semibold"
            price={initialPriceValues[1]}
          />
          <Link
            className="flex justify-center ml-2 items-center pr-2"
            href={clearRangeFilterUrl()}
          >
            <SvgClose fill="white" fontSize={8} onClick={startFiltersLoading} />
          </Link>
        </div>
      )}

      {areFiltersPresent && (
        <Link
          href={getCategoryId({
            pathname: url.pathname,
            search: url.searchParams.toString(),
            storeCode,
          })}
          onClick={startFiltersLoading}
          scroll={false}
          className="bg-secondary text-white text-sm px-4 py-1 font-semibold"
        >
          {t('filter.clear')}
        </Link>
      )}
    </div>
  )
}
