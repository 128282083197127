'use client'
import { twJoin } from 'tailwind-merge'

import SvgArrowSimpleTop from '@/components/icons/svg/arrow-simple-top'
import { categorySidebarItem } from '@/common/constants/html-element-constants'

export type CategorySidebarButtonProps = {
  elementId: string
  label: string
  initialOpen: boolean
}

export const CategorySidebarButton = ({
  elementId,
  label,
  initialOpen,
}: CategorySidebarButtonProps) => {
  const setOpen = (idToSearch: string) => {
    document
      .getElementById(`${categorySidebarItem}${idToSearch}`)
      ?.classList.toggle('hidden')
    document
      .getElementById(`arrow${idToSearch}`)
      ?.classList.toggle('rotate-180')
  }

  return (
    <button
      type="button"
      id={`button${elementId}`}
      key={elementId}
      title={label}
      onClick={() => setOpen(elementId)}
    >
      <SvgArrowSimpleTop
        id={`arrow${elementId}`}
        className={twJoin('my-auto', !initialOpen ? 'rotate-180' : '')}
      />
    </button>
  )
}
