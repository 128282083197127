import { useSearchParams } from 'next/navigation'
import { useCallback } from 'react'

export function useUpdateSearchParam() {
  const searchParams = useSearchParams()

  const updateSearchParam = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString())

      params.set(key, value)

      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params.toString()}`,
      )
    },
    [searchParams],
  )

  return updateSearchParam
}
