'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'

import { RangeSlider } from '@/components/range-slider'
import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'
import { getURLObjectSafely } from '@/common/utils/url-utils'
import { Link } from '@/components/link/link'

type Props = {
  href: string
  range: { from: number; to: number; formMax: number; formMin: number }
}

const CategoryFiltersPrice = ({ href, range }: Props) => {
  const { startFiltersLoading } = useCategoryFilters()
  const t = useTranslations('Category')
  const filterLinkRef = useRef<HTMLAnchorElement>(null)
  const [filterUrl, setFilterUrl] = useState('')

  const handlePriceChange = (min: number, max: number) => {
    const url = getURLObjectSafely(href)

    url.searchParams.set('price', `${min}-${max}`)
    url.searchParams.set('filter', 'out')

    startFiltersLoading()
    setFilterUrl(url.href)
  }

  useEffect(() => {
    if (filterUrl) {
      requestAnimationFrame(() => {
        filterLinkRef.current?.click()
      })
    }
  }, [filterUrl])

  return (
    <div className="flex flex-col md:flex-row border-b border-black py-4">
      <h3 className="font-bold basis-3/12 pb-2 md:pb-0">
        {t('filter.price').toUpperCase()}
      </h3>
      <div className="basis-9/12">
        <RangeSlider
          min={range.formMin}
          max={range.formMax}
          initialValue={[range.from, range.to]}
          onValueChange={([min, max]) => {
            handlePriceChange(min, max)
          }}
          minStepsBetweenThumbs={1}
        />
      </div>
      <Link
        ref={filterLinkRef}
        className="hidden"
        href={filterUrl}
        scroll={false}
        aria-hidden
      />
    </div>
  )
}

export default CategoryFiltersPrice
