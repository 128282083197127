import { Label, Maybe, ProductStockStatus } from '@/api'
import { convertAbsoluteUrlToRelative } from '@/common/utils/url-utils'
import { ProductListItem } from '@/common/types/product-types'
import { ListItemProductType } from '@/common/utils'
import { CategorySearchProductGQL } from '@/modules/category/category-types'

export function getPathname(fullUrl: Maybe<string> | undefined): string {
  if (!fullUrl) return ''
  const url = new URL(fullUrl)
  return url.pathname
}

//TODO: This function is not complete. It needs to be completed according to the response from BE
export function categorySearchProductToCategoryProductListItemData(
  product?: CategorySearchProductGQL | null,
): ProductListItem {
  const isGiftCard = product?.id === 74677 // TODO: remove after API is fixed

  return {
    type:
      product?.__typename ?? isGiftCard
        ? ListItemProductType.GiftCard
        : ListItemProductType.Configurable, // TODO: remove after API is fixed
    name: product?.name ?? '-',
    enabled: product?.is_saleable === 'true',
    /**
     * single variant products have only two skus.
     * Parent sku is the first sku and child sku is the second sku
     */
    sku:
      product?.skus && product?.skus.length === 2 ? product?.skus[0] ?? '' : '',
    thumbnail: {
      url: product?.thumbnail ? product?.thumbnail.toString() : '',
      label: product?.name ?? 'Product List Item Label',
    },
    priceRange: {
      isPriceRange: product?.is_price_range ?? false,
      price: product?.price ?? 0,
      currency: 'EUR',
    },
    stockStatus:
      product?.is_saleable === 'true'
        ? ProductStockStatus.InStock
        : ProductStockStatus.OutOfStock,
    ratingSummary: product?.rating_summary
      ? Number(product?.rating_summary)
      : 0,
    reviewCount: product?.reviews_count ? Number(product?.reviews_count) : 0,
    manufacturerInfo: {
      name: product?.manufacturer?.name ?? '-',
      pathname: '',
    },
    breadcrumbEn: product?.breadcrumbs_en ?? undefined,
    canonicalUrl: convertAbsoluteUrlToRelative(new URL(product?.product_url!)), //getPathname(product?.product_url), This product detail page URL.
    productLabels:
      product?.product_labels?.filter(
        (label): label is Label => label !== null,
      ) ?? [],
    skus: product?.skus
      ? product?.skus
          .filter((sku) => sku !== undefined && sku !== null && sku !== '')
          .map((sku) => {
            if (sku === null) return ''
            return sku
          })
      : [],
    image: {
      url: {
        small: product?.image ?? '',
      },
      label: product?.name ?? '',
    },
    singleVariantSku: product?.single_variant_sku ?? undefined,
    id: product?.id?.toString() ?? '0',
    configurableVariants: [],
    isBestseller: false,
    isProductSingleVariant: product?.is_single_variant === 'true',
    isSaleable: product?.is_saleable === 'true',
    longTermUnavailable: 0,
    finalPrice: product?.price ?? 0, // cannot be optional - TODO: send to Magento core ticket to fix their data structures
  }
}
