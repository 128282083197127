import { useCallback, useRef, useState } from 'react'

import { RECAPTCHA_ENDPOINT } from 'src/middleware/routes/middleware-captcha-route'

export function useCaptcha() {
  const tokenRef = useRef('')
  const [refresh, setRefresh] = useState(false)

  const refreshToken = () => {
    setRefresh((r) => !r)
  }

  const handleVerify = useCallback((token) => {
    tokenRef.current = token
  }, [])

  // TODO: convert to API endpoint
  const checkToken = async (): Promise<boolean> => {
    const captchaResponse = await fetch(
      RECAPTCHA_ENDPOINT + tokenRef.current,
    ).then((res) => res.json())

    return !!captchaResponse?.data?.success
  }

  return {
    shouldRefresh: refresh,
    refreshToken,
    handleVerify,
    checkToken,
  }
}
