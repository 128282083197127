'use client'

import React, { useState } from 'react'

import { ProductListItem } from '@/components/product-list-item/product-list-item'
import {
  PurchaseDialog,
  PurchaseDialogProduct,
} from '@/components/purchase-dialog/purchase-dialog'
import {
  ProductListItem as ProductListItemType,
  ProductType,
} from '@/common/types/product-types'
import { useCartContext } from '@/providers'
import { useDisclosure } from '@/common/hooks/use-disclosure'
import {
  AddToCartEventHandlerParams,
  SimpleVariantAddToCartParams,
} from '@/providers/cart/cart.types'
import {
  addConfigProductToCart,
  addSimpleProductToCart,
} from '@/components/purchase-dialog/purchase-dialog.utils'

export type CategoryProductsItemProps = {
  productsDataItems?: ProductListItemType[]
  onProductClick?: Function
}

export const CategoryProductsItem = ({
  productsDataItems,
  onProductClick,
}: CategoryProductsItemProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { addToCart, isCartRefetching, isAddingToCart } = useCartContext()
  const [dialogProductData, setDialogProductData] = useState<
    PurchaseDialogProduct | undefined
  >()

  const handleOnAddToCartEvent = async ({
    variant,
    ...rest
  }: AddToCartEventHandlerParams) => {
    const trackingOptions = {
      configId: Number(dialogProductData?.id ?? 0),
      simpleId: Number(variant?.product?.id ?? 0),
    }

    addConfigProductToCart({ variant, ...rest }, trackingOptions)
  }

  const handleSimpleOnAddToCartEvent = async (
    params: SimpleVariantAddToCartParams,
  ) => {
    addSimpleProductToCart(params, addToCart)
  }

  const isCartButtonDisabled = !addToCart || isCartRefetching || isAddingToCart

  const handleAddToCartButtonClick = (product: ProductListItemType) => {
    if (!product.isProductSingleVariant) {
      setDialogProductData({
        ...product,
        productType: product.type,
        parentSku: product.sku,
      })
      onOpen()
    } else {
      handleSimpleOnAddToCartEvent({
        productType: ProductType.Configurable,
        brand: product.manufacturerInfo.name,
        category: product.breadcrumbEn,
        parentSku: product.sku ?? undefined,
        price: product.finalPrice,
        productName: product.name,
        sku: product.singleVariantSku,
        configId: Number(product.id),
        // TODO: currently using this value because magento is not sending us ids of simple variants
        simpleId: 0,
      })
    }
  }

  return (
    <>
      {dialogProductData && (
        <PurchaseDialog
          productListData={dialogProductData}
          onClose={() => {
            onClose()
            setDialogProductData(undefined)
          }}
          onAddToCartEvent={handleOnAddToCartEvent}
          isOpen={isOpen}
        />
      )}

      {productsDataItems?.map((product) => {
        return (
          <ProductListItem
            key={product.id}
            productType={product.type}
            isCartButtonDisabled={isCartButtonDisabled}
            onProductClick={onProductClick}
            onAddToCartButtonClick={() => handleAddToCartButtonClick(product)}
            {...product}
            id={Number(product.id)}
          />
        )
      })}
    </>
  )
}
