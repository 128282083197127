import { HTMLAttributes } from 'react'

import { cn } from '@/common/utils/lib'

export const CloseButton = ({
  className,
  ...props
}: HTMLAttributes<HTMLElement>) => (
  <button
    type="button"
    className={cn(
      'text-4xl font-light text-gray-900',
      'hover:text-gray-400',
      'px-4',
      className,
    )}
    {...props}
  >
    &times;
  </button>
)
