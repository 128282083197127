import { useCallback, useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { stripIgnoredCharacters } from 'graphql'

import { cn } from '@/common/utils/lib'
import { Field } from '@/components/field'
import { Input } from '@/components/input'
import { Button } from '@/components/button'
import { Textarea } from '@/components/textarea'
import { Modal } from '@/components/modal/modal'
import { delay, getGraphqlApi } from '@/common/utils/common.utils'
import { WithClassName } from '@/common/types/utility'
import { useCaptcha } from '@/common/hooks/use-captcha'
import { useFullScreenSpinner } from '@/providers/full-screen-spinner'
import {
  RequestItemDefaultValues,
  useRequestItemForm,
} from './use-request-item-form'
import { CloseButton } from './close-button'
import ReCaptchaBrandingText from '@/components/recaptcha-branding-text'
import { AddCategoryNoteDocument } from '@/api'
import { HEADERS } from '@/common/types/header-types'
import { useStoreContext } from '@/providers'
import { consoleError } from '@/common/utils/console'
import { ModalState } from '../category-products-request-modal'

type FormModalProps = {
  isOpen: boolean
  onClose: () => void
  id?: number | null
  modalState: ModalState
  setModalState: (state: ModalState) => void
}

export const RequestItemModal = ({
  isOpen,
  onClose,
  id,
  modalState,
  setModalState,
}: WithClassName<FormModalProps>) => {
  const t = useTranslations()
  const spinner = useFullScreenSpinner()
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useRequestItemForm()
  const { shouldRefresh, refreshToken, handleVerify, checkToken } = useCaptcha()

  const { storeCode } = useStoreContext()

  useEffect(() => {
    const closeModal = async () => {
      if (!isSubmitSuccessful) {
        return
      }
      /**
       * If spinner is NOT visible, an error was thrown in `onSubmit` meaning
       * no delay to show the changed text of the submit button is needed.
       * Neither the modal needs to be closed.
       */
      if (spinner.isVisible) {
        await delay(1000)
      }

      spinner.onClose()
    }
    closeModal()
  }, [isSubmitSuccessful, spinner])

  const onSubmit = handleSubmit(async (form) => {
    try {
      spinner.onOpen()
      const isChecked = await checkToken()

      if (!isChecked) {
        return
      }

      const transformNotes = `${form.notes} | added on category ${window.location.href}`
      if (!id) {
        throw new Error('Category id is missing')
      }

      const input = {
        categoryId: id,
        productName: form.productName,
        note: transformNotes,
      }

      const api = getGraphqlApi()

      /**
       * TODO: In second iteration of this task, the header name will be changed from
       * Authorization to <x-api-key>
       * That is also the reason why we are using raw fetch, because we had to bypass
       * default Authorization header
       */
      const response = await fetch(`${api}/graphql`, {
        method: 'POST',
        headers: {
          [HEADERS.CT]: 'application/json',
          [HEADERS.STORE]: storeCode,
          [HEADERS.AUTH]: `Bearer ${process.env.NEXT_PUBLIC_ADD_NOTE_AUTHORIZATION}`,
        },
        body: JSON.stringify({
          query: stripIgnoredCharacters(AddCategoryNoteDocument),
          variables: input,
        }),
      })

      if (!response.ok) {
        consoleError('Response - not ok', {
          error: response?.status,
          input,
        })
        throw new Error('Response - not ok', {
          cause: { status: response?.status },
        })
      }
      const json = (await response.json()) ?? {}
      if (json.errors) {
        consoleError('Response - not ok', {
          error: json.errors,
          input,
        })
        throw new Error('Response - error', {
          cause: { json },
        })
      }
      setModalState('success')
    } catch (err) {
      setModalState('error')

      spinner.onClose()
    }
  })

  const handleClose = useCallback(() => {
    reset(RequestItemDefaultValues)
    refreshToken()
    onClose()
  }, [reset, onClose, refreshToken])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className="w-[90%] md:w-[428px] p-8"
    >
      <CloseButton onClick={handleClose} className="absolute top-0 right-0" />

      <p className="font-extrabold text-xl md:text-xl pb-5">
        {t('Category.products.requestProduct.headline')}
      </p>

      {modalState === 'form' ? (
        <form onSubmit={onSubmit} className="flex flex-col gap-y-3">
          <GoogleReCaptcha
            onVerify={handleVerify}
            refreshReCaptcha={shouldRefresh}
          />

          <Field
            label={t('Category.products.requestProduct.productName')}
            error={errors?.productName?.message}
            required
          >
            <Input
              type="text"
              variant={errors?.productName?.message ? 'error' : 'normal'}
              {...register('productName')}
            />
          </Field>

          <Field label={t('Category.products.requestProduct.notes')}>
            <Textarea
              rows={4}
              variant={errors?.notes?.message ? 'error' : 'normal'}
              {...register('notes')}
            />
          </Field>

          <ReCaptchaBrandingText />

          <Button
            type="submit"
            size="base"
            align="center"
            variant="secondary"
            className={cn(
              'w-full px-7',
              isSubmitSuccessful && 'bg-tooltip-text',
            )}
          >
            {t(
              isSubmitSuccessful
                ? 'Category.products.requestProduct.button.submitted'
                : 'Category.products.requestProduct.button.submit',
            )}
          </Button>
        </form>
      ) : (
        <div>
          <p className="text-center text-lg font-semibold">
            {modalState === 'success'
              ? t('Category.products.requestProduct.success')
              : t('Category.products.requestProduct.error')}
          </p>
        </div>
      )}
      {(modalState === 'error' || modalState === 'success') && (
        <Button
          onClick={handleClose}
          variant="primary"
          size="sm"
          className="mt-5 w-full"
        >
          {t('Product.common.button.close')}
        </Button>
      )}
    </Modal>
  )
}
