'use client'

import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { twJoin } from 'tailwind-merge'

import { Currency } from '../currency'

const RangeSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={twJoin(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-gray-500">
      <SliderPrimitive.Range className="absolute h-full bg-primary" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full bg-primary" />
    <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full bg-primary" />
  </SliderPrimitive.Root>
))

RangeSlider.displayName = SliderPrimitive.Root.displayName

export default function Slider({
  initialValue,
  onValueChange,
  ...props
}: Omit<
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    initialValue: number[]
  },
  'value'
>) {
  const [currValue, setCurrValue] = React.useState(initialValue)
  const [min, max] = currValue

  React.useEffect(() => {
    setCurrValue(initialValue)
  }, [initialValue])

  return (
    <div className="flex flex-col gap-4">
      <RangeSlider
        value={currValue}
        onValueChange={setCurrValue}
        onValueCommit={onValueChange}
        {...props}
      />
      <div className="flex flex-row w-fit mx-auto">
        <span className="text-sm text-gray-500">
          <Currency price={min} /> - <Currency price={max} />
        </span>
      </div>
    </div>
  )
}
