import * as React from 'react'
import { TextareaHTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

const variants = cva(
  [
    'text-base',
    'leading-7',
    'px-3',
    'w-full',
    'transition',
    'rounded-none',
    'hover:border-[#757779]',
    'focus:rounded-none',
    'focus:shadow-md',
    'focus:shadow-grey-200',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
    'outline-none',
  ],
  {
    variants: {
      variant: {
        normal: ['border-2', 'border-black'],
        error: ['border-2', 'border-red-500'],
      },
      disabled: {
        true: ['hover:border-auto'],
      },
    },
    defaultVariants: {
      variant: 'normal',
    },
  },
)

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'normal' | 'error'
  required?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ variant, required, className, ...props }, ref) => {
    return (
      <textarea
        className={twMerge(
          variants({ variant, disabled: props.disabled, className }),
        )}
        ref={ref}
        required={required}
        aria-required={required}
        {...props}
      />
    )
  },
)

Textarea.displayName = 'Textarea'
export { Textarea }
