'use client'

import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '../link/link'
import { getIsExternal, getSkeletonTarget } from '@/common/utils'
import { useStoreContext } from '@/providers'

export type BreadcrumbProps = {
  name: string
  url: string
}

export function Breadcrumb({ name, url }: BreadcrumbProps) {
  const { storeCode } = useStoreContext()
  const isExternal = getIsExternal(storeCode, url)
  const target = getSkeletonTarget(storeCode, url)

  return (
    <li
      data-test={selectors.PDP.breadcrumbs}
      key={url}
      className="inline-flex my-1"
    >
      <Link
        href={url}
        className="h-full inline-flex items-center hover:underline"
        aria-label={name}
        isExternal={isExternal}
        skeletonLoader={{ target }}
      >
        <span className="inline-flex font-semibold">{name}</span>
      </Link>
      <span className="inline-flex justify-center mx-1.5">&raquo;</span>
    </li>
  )
}
