'use client'

import { useCallback, useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { useInfiniteQuery } from '@tanstack/react-query'
import { usePathname, useSearchParams } from 'next/navigation'

import {
  categoryProductsPageSize,
  productIdPrefix,
  scrollToGoBackSessionKey,
} from '@/common/constants/products'
import { Button } from '@/components/button'
import { PageInfo } from '@/components/product-list-item/product-data-item-types'
import { selectors } from '@/common/constants/selectors-constants'
import { CategoryProductsItem } from './category-products-item'
import {
  CategoryPageProductsTreeFragment,
  useCategoryPageProductsQuery,
} from '@/api'
import { categorySearchProductToCategoryProductListItemData } from '@/components/product-list-item/utils/category-search-product-to-category-product-list-item-data'
import { consoleError } from '@/common/utils/console'
import { useUpdateSearchParam } from '@/common/hooks/use-update-search-params'
import { ProductListItemSkeleton } from '@/components/product-list-item/product-list-item-skeleton'
import CategoryProductsRequestModal from './category-products-request-modal'
import { getURLObjectSafely } from '@/common/utils/url-utils'
import { getCategoryUrl, getSortingInput } from '@/common/utils'

interface CategoryProductsClientProps {
  initialPageInformation: PageInfo
}

interface GoBackScrollData {
  categoryId: string
  productId: number
}

export function CategoryProductsClient({
  initialPageInformation,
}: CategoryProductsClientProps): JSX.Element {
  const t = useTranslations('Category')
  const updateSearchParam = useUpdateSearchParam()
  const path = usePathname()
  const searchParams = useSearchParams()
  const { href } = getURLObjectSafely(
    `http://localhost:3000${path}?${searchParams.toString()}`,
  )
  const categoryId = getCategoryUrl(href)
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['categoryProducts', categoryId],
    queryFn: async ({ pageParam }) => {
      const products = await useCategoryPageProductsQuery.fetcher({
        categoryId,
        currentPage: pageParam,
        pageSize: categoryProductsPageSize,
        sort: getSortingInput(href),
      })()

      return products
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.route?.__typename !== 'CategoryTree') {
        return undefined
      }

      const totalCount =
        lastPage.route?.categorySearch?.total_count ?? Number.NEGATIVE_INFINITY

      return totalCount >
        (initialPageInformation.currentPage - 1 + allPages.length) *
          categoryProductsPageSize
        ? allPages.length + initialPageInformation.currentPage
        : undefined
    },
    initialPageParam: initialPageInformation.currentPage,
  })

  useEffect(() => {
    const sessionValue = window.sessionStorage.getItem(scrollToGoBackSessionKey)

    if (sessionValue) {
      try {
        const scrollToProductItem: GoBackScrollData = JSON.parse(sessionValue)
        if (categoryId === scrollToProductItem.categoryId) {
          const element = document.getElementById(
            `${productIdPrefix}${scrollToProductItem.productId}`,
          )
          element?.scrollIntoView()
        }
        window.sessionStorage.removeItem(scrollToGoBackSessionKey)
      } catch (e) {
        consoleError('Error restoring scroll position:', e)
      }
    }
  }, [categoryId])

  const handleInView = useCallback(
    (pageIndex: number) => {
      updateSearchParam('p', pageIndex.toString())
    },
    [updateSearchParam],
  )

  const handleOnProductClick = (productId: number) => {
    const sessionData: GoBackScrollData = {
      productId,
      categoryId,
    }
    window.sessionStorage.setItem(
      scrollToGoBackSessionKey,
      JSON.stringify(sessionData),
    )
  }

  const productsData = data?.pages.map((page) => {
    const items = page?.route as CategoryPageProductsTreeFragment

    return {
      totalCount: items?.categorySearch?.total_count,
      products: items?.categorySearch?.items?.map(
        categorySearchProductToCategoryProductListItemData,
      ),
    }
  })

  const totalItems = productsData?.at(0)?.totalCount ?? 0

  const currentPage =
    (data?.pages.length ?? 0) + initialPageInformation.currentPage
  const itemsPerPage = categoryProductsPageSize
  const startItem = (initialPageInformation.currentPage - 2) * itemsPerPage + 1
  const endItem = Math.min((currentPage - 1) * itemsPerPage, totalItems)
  const shouldLoadNextPage = totalItems >= startItem

  return (
    <>
      <div className="pt-4">
        <div>
          <div className="w-full flex flex-col gap-4">
            <div
              className="w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 justify-center"
              data-test={selectors.CP.products}
            >
              {productsData?.map((page, index) => (
                <CategoryProductsItem
                  key={index}
                  productsDataItems={page.products}
                  onProductClick={handleOnProductClick}
                />
              ))}
            </div>
          </div>
        </div>

        {isFetching && (
          <div className="mt-6">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {[...Array(12)].map((_, index) => (
                <ProductListItemSkeleton key={index} />
              ))}
            </div>
          </div>
        )}

        <div className="w-full mt-7 mb-10">
          <div className="my-auto">
            {!hasNextPage && endItem === totalItems && (
              <CategoryProductsRequestModal id={categoryId} />
            )}

            {shouldLoadNextPage && status === 'success' && (
              <p className="text-gray-400 text-sm font-semibold order-last md:order-first">
                {t('products.info', {
                  from: startItem,
                  to: endItem,
                  totalCount: totalItems,
                })}
              </p>
            )}
          </div>

          {hasNextPage && (
            <div className="flex justify-center">
              <Button
                title={t('products.showMore')}
                variant="secondary"
                size="sm"
                onClick={() => {
                  handleInView(currentPage)
                  fetchNextPage()
                }}
                disabled={isFetchingNextPage}
                className="whitespace-normal font-extrabold"
              >
                {t('products.showMore')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
