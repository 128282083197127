'use client'

import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'

import { Flex } from '@/components/flex'
import { CategorySelect } from '@/components/select'
import SvgArrowThinDown from '@/components/icons/svg/arrow-thin-down'
import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'
import { SortingOptions, SortingValues } from './filters.const'
import { AvailableCategorySearchFilters } from '../../category-types'
import { SEARCH_PARAMS } from '@/common/constants/url-constants'
import { getURLObjectSafely } from '@/common/utils/url-utils'
import { getCategoryUrl } from '@/common/utils'
import { Link } from '@/components/link/link'

type CategoryFiltersButtonsProps = {
  href: string
  availableFilters: AvailableCategorySearchFilters
  categoryId: string
}

export const CategoryFiltersButtons = ({
  href,
  availableFilters,
  categoryId,
}: CategoryFiltersButtonsProps) => {
  const url = getURLObjectSafely(href)
  const [filterUrl, setFilterUrl] = useState('')
  const filterLinkRef = useRef<HTMLAnchorElement>(null)
  const t = useTranslations('Category')
  const { onToggleFilters, isShowingFilters, startFiltersLoading } =
    useCategoryFilters()

  const sortingSearchParamValue =
    url.searchParams.get(SEARCH_PARAMS.CategorySorting) ??
    SortingValues.Bestseller
  const isSortingValueValid = Object.values(SortingValues).includes(
    sortingSearchParamValue as SortingValues,
  )

  const selectedSortingValue = isSortingValueValid
    ? sortingSearchParamValue
    : SortingValues.Bestseller

  const handleSortingChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const url = new URL(window.location.href)
    const id = `/${categoryId}`
    const sorting = e.target.value as SortingValues

    // Note: Remove sorting to have the correct category URL (also it will be applied in this handler)
    url.searchParams.delete(SEARCH_PARAMS.CategorySorting)
    // Note: By default, filter-out is applied to all sorting
    url.searchParams.set(SEARCH_PARAMS.Filter, 'out')

    const categoryUrl = getCategoryUrl(url.href)

    url.searchParams.set(SEARCH_PARAMS.CategorySorting, sorting)

    // Note: Only apply filter-out to reviews count sorting if it's not the default category
    if (sorting === SortingValues.ReviewsCount) {
      if (categoryUrl === id) {
        url.searchParams.delete(SEARCH_PARAMS.Filter)
      }
    }

    // Note: Remove sorting and filter-out if it's the default sorting
    if (sorting === SortingValues.Bestseller) {
      url.searchParams.delete(SEARCH_PARAMS.CategorySorting)

      // Note: Remove filter-out if it's the default category
      if (categoryUrl === id) {
        url.searchParams.delete(SEARCH_PARAMS.Filter)
      }
    }

    startFiltersLoading()
    setFilterUrl(url.toString())
  }

  const amountOfActiveFilters = useMemo(
    () =>
      (availableFilters?.reduce(
        (acc, curr) =>
          acc + (curr?.options?.filter((option) => option?.used).length ?? 0),
        0,
      ) ?? 0) + (url.searchParams.get('price') ? 1 : 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableFilters, href],
  )

  useEffect(() => {
    if (filterUrl) {
      requestAnimationFrame(() => {
        filterLinkRef.current?.click()
      })
    }
  }, [filterUrl])

  return (
    <Flex className="flex-col usm:flex-row usm:justify-end usm:space-x-2 space-y-2 usm:space-y-0 gap-2 mt-4">
      <CategorySelect
        onChange={handleSortingChange}
        value={selectedSortingValue}
      >
        {SortingOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {t(label)}
          </option>
        ))}
      </CategorySelect>

      <button
        onClick={onToggleFilters}
        className="bg-grey-300 text-white text-sm pl-2 pr-4 py-1 font-bold"
      >
        <span className="flex flex-row gap-2">
          <SvgArrowThinDown
            aria-expanded={isShowingFilters}
            className="my-auto fill-white rotate-0 aria-expanded:rotate-180"
          />
          {isShowingFilters ? t('filter.hide') : t('filter.show')}
          {amountOfActiveFilters > 0 && ` (${amountOfActiveFilters})`}
        </span>
      </button>

      <Link
        ref={filterLinkRef}
        className="hidden"
        href={filterUrl}
        scroll={false}
        aria-hidden
      />
    </Flex>
  )
}
