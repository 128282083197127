'use client'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'

import { Link } from '@/components/link/link'
import { useClickOutside } from '@/common/hooks/click-outside.hook'
import { CategoryFilter, CategoryFilterOption } from '../../../category-types'
import SvgSearch from '@/components/icons/svg/search'
import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'

type Props = {
  filter: CategoryFilter | undefined | null
  availableFilterOptions?: Array<CategoryFilterOption | null> | null
}

const CategoryFiltersMultiselectOthers = ({
  filter,
  availableFilterOptions,
}: Props) => {
  const [currOthersOpened, setCurrOthersOpened] = useState<string | undefined>(
    undefined,
  )
  const dropdownRef = useRef<Map<string, HTMLDivElement>>(new Map())
  const { registerClickOutsideDetection } = useClickOutside()
  const [searchTermForOthers, setSearchTermForOthers] = useState<string>('')
  const { startFiltersLoading } = useCategoryFilters()
  const t = useTranslations('Category')

  useEffect(() => {
    let reset: () => void = () => {}
    if (currOthersOpened) {
      reset = registerClickOutsideDetection(
        dropdownRef.current.get(currOthersOpened)!,
        () => {
          setCurrOthersOpened(undefined)
          setSearchTermForOthers('')
        },
      )
    }

    return () => reset()
  }, [registerClickOutsideDetection, currOthersOpened])

  const handleOnOthersClick = useCallback(
    (name?: string) => {
      if (!name) {
        setCurrOthersOpened(undefined)
        setSearchTermForOthers('')
        return
      }

      if (currOthersOpened === name) {
        setCurrOthersOpened(undefined)
      } else {
        setCurrOthersOpened(name)
      }

      setSearchTermForOthers('')
    },
    [currOthersOpened],
  )

  const filteredAvailableOptions = useMemo(
    () =>
      availableFilterOptions?.filter((option) =>
        option?.name?.toLowerCase().includes(searchTermForOthers.toLowerCase()),
      ),
    [availableFilterOptions, searchTermForOthers],
  )

  return (
    <div className="flex flex-row">
      <div className="grow" />
      <div className="grow-0 relative inline-block text-left w-32 px-2 py-1 border-2 border-slate-700">
        <div
          className="flex flex-row items-center space-x-2 text-xs cursor-pointer"
          onClick={() => handleOnOthersClick(filter?.name ?? '')}
        >
          <span className="grow text-gray-500">{t('filter.others')}</span>
          <SvgSearch className="fill-black" />
        </div>
        {currOthersOpened === filter?.name && (
          <div
            ref={(ref) => {
              if (ref) {
                dropdownRef.current.set(filter?.name ?? '', ref)
              }
            }}
            className="absolute right-0 z-10 mt-2 py-1 w-full origin-top-right bg-white border-2 border-slate-700 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="mx-1 flex flex-row border-2 border-slate-700 items-center p-1">
              <input
                type="text"
                className="w-full text-xs font-semibold focus:outline-none"
                value={searchTermForOthers}
                onChange={(e) => setSearchTermForOthers(e.target.value)}
              />
              <SvgSearch className="fill-black" />
            </div>
            <div className="max-h-44 overflow-y-auto " role="none">
              {filteredAvailableOptions?.map((option, index) => {
                if (!option?.url) {
                  return null
                }

                return (
                  <Link
                    href={option?.url}
                    aria-selected={option?.used ?? false}
                    className="block p-1 text-xs hover:text-white hover:bg-secondary aria-selected:text-white aria-selected:bg-secondary"
                    key={`multiselect-others-${option?.name}-${index}`}
                    scroll={false}
                    onClick={startFiltersLoading}
                  >
                    {option?.name} ({option?.count})
                  </Link>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CategoryFiltersMultiselectOthers
