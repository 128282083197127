import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslations } from 'next-intl'

export const RequestItemDefaultValues = {
  notes: '',
  productName: '',
}

const useRequestItemFormSchema = () => {
  const t = useTranslations('Product')

  return z.object({
    notes: z.string().nullable(),
    productName: z
      .string()
      .min(1, { message: t('detail.notification.input.required') }),
  })
}

export type RequestItemForm = z.infer<
  ReturnType<typeof useRequestItemFormSchema>
>

export const useRequestItemForm = () =>
  useForm<RequestItemForm>({
    resolver: zodResolver(useRequestItemFormSchema()),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: RequestItemDefaultValues,
  })
