'use client'
import { twJoin } from 'tailwind-merge'

import { SideNavItem } from '@/components/header/api'
import { CategorySidebarButton } from './category-sidebar-button'
import { Link } from '@/components/link/link'

export type CategorySidebarLinkProps = {
  navigationItem: SideNavItem
  parentId: string
  className?: string
}

export const CategorySidebarLink = ({
  navigationItem,
  parentId,
  className = '',
}: CategorySidebarLinkProps) => {
  return (
    <div className="flex justify-between">
      <Link
        className={twJoin(
          'text-sm hover:text-secondary',
          className,
          navigationItem.active ? 'text-secondary' : '',
        )}
        href={navigationItem.href}
        title={navigationItem.label}
        skeletonLoader={{
          target: 'category',
        }}
      >
        {navigationItem.label}
      </Link>
      {navigationItem.items.length ? (
        <CategorySidebarButton
          elementId={`${parentId}${navigationItem.id}`}
          label={navigationItem.label}
          initialOpen={navigationItem.isOpen}
        />
      ) : null}
    </div>
  )
}
