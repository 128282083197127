'use client'

import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { useUserTracking } from '@/common/hooks/user-tracking-hook'
import {
  GtmScheduler,
  GtmTrackerState,
} from '@/common/services/tracking/google/gtm-scheduler'
import { createPageGtmScheduler, trackPage } from './page-tracking-utils'
import { TrackingPage } from '../google/gtm-types'

/**
 * Decided to create this as component instead of a hook, because in category page
 * we need to use it conditionally
 */
export function PageTracking({
  page,
  categories,
  productIds,
}: {
  page: TrackingPage
  categories: string[] | string
  productIds?: number[]
}) {
  const { trackUser, loaded } = useUserTracking()
  const gtmScheduler = useRef<GtmScheduler>()
  const pathname = usePathname()

  if (!gtmScheduler.current) {
    gtmScheduler.current = createPageGtmScheduler()
  }

  useEffect(() => {
    if (gtmScheduler.current) {
      trackPage({
        page,
        categories,
        gtmTracker,
        gtmScheduler: gtmScheduler.current,
        loaded,
        trackUser,
      })
    }
  }, [trackUser, loaded, page, categories])

  useEffect(() => {
    if (pathname) {
      gtmScheduler.current?.scheduleInitialEvent(
        GtmTrackerState.VirtualPageview,
        () => gtmTracker.trackVirtualPageView(pathname),
      )

      if (productIds) {
        gtmScheduler.current?.scheduleInitialEvent(
          GtmTrackerState.Remarketing,
          () =>
            gtmTracker.trackRemarketingCategory({
              productIds,
            }),
        )
      }
    }
  }, [pathname, productIds])

  return null
}
