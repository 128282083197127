'use client'
import { useEffect, useMemo } from 'react'

import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'
import CategoryFiltersPrice from './category-filters-price'
import { CategoryFiltersButtons } from './category-filters-buttons'
import CategoryFiltersCheckboxes from './category-filters-checkboxes'
import { CategoryFiltersMultiselect } from './category-filters-multiselect/category-filters-multiselect'
import {
  AvailableCategorySearchFilters,
  CategoryFiltersCheckbox,
} from '../../category-types'
import SelectedCategoryFilters from './selected-category-filters'
import { getURLObjectSafely } from '@/common/utils/url-utils'

type Props = {
  availableFilters: AvailableCategorySearchFilters
  href: string
  categoryId: string
}

const CategoryFilters = ({ availableFilters, href, categoryId }: Props) => {
  const url = getURLObjectSafely(href)
  const { isShowingFilters, onFiltersLoaded } = useCategoryFilters()

  useEffect(() => {
    onFiltersLoaded()
    // this hook depends on availableFilters because when they are loaded from server
    // we want to stop showing the loading spinner
  }, [availableFilters, onFiltersLoaded])

  const areFiltersAvailable =
    availableFilters?.length !== 0 ||
    availableFilters?.every((filter) => filter?.code === 'price')

  const checkboxFilters: CategoryFiltersCheckbox[] | undefined = useMemo(
    () =>
      availableFilters
        ?.filter((filter) => filter?.type === 'checkbox')
        .map((filter) => ({
          eventId: filter?.global_name,
          name: filter?.name,
          count: filter?.options?.reduce(
            (acc, option) => acc + (option?.count ?? 0),
            0,
          ),
          used: filter?.options?.some((option) => option?.used),
          url: filter?.options?.find((option) => option?.url)?.url,
        })),
    [availableFilters],
  )

  const range = availableFilters?.find(
    (filter) => filter?.type === 'range',
  )?.range

  const initialPriceValues = url.searchParams.get('price')
    ? [
        parseFloat(
          url.searchParams.get('price')?.split('-')[0] ?? `${range?.formMin}`,
        ),
        parseFloat(
          url.searchParams.get('price')?.split('-')[1] ?? `${range?.formMax}`,
        ),
      ]
    : [range?.formMin, range?.formMax]

  return (
    <>
      <CategoryFiltersButtons
        categoryId={categoryId}
        availableFilters={availableFilters}
        href={href}
      />

      {isShowingFilters && areFiltersAvailable && (
        <>
          <CategoryFiltersMultiselect availableFilters={availableFilters} />

          <CategoryFiltersPrice
            href={href}
            range={{
              from: (initialPriceValues && initialPriceValues[0]) ?? 0,
              to: (initialPriceValues && initialPriceValues[1]) ?? 0,
              formMin: range?.formMin ?? 0,
              formMax: range?.formMax ?? 0,
            }}
          />

          <CategoryFiltersCheckboxes checkboxFilters={checkboxFilters} />
        </>
      )}
      <SelectedCategoryFilters filters={availableFilters} href={href} />
    </>
  )
}

export default CategoryFilters
