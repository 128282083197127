import { NextRequest, NextResponse } from 'next/server'

import { MiddlewareRoute } from '../middleware-types'

export function getCaptchaRoute(): MiddlewareRoute {
  return {
    name: '*/api/recaptcha-google*',
    match: (url) => url.pathname.includes('/api/recaptcha-google'),
    handle: ({ request }) => handleCaptchaRequest(request),
  }
}

export const RECAPTCHA_ENDPOINT = '/api/recaptcha-google?reCaptchaToken='

async function handleCaptchaRequest(
  request: NextRequest,
): Promise<NextResponse> {
  if (request.method !== 'GET') {
    return NextResponse.next()
  }

  const reCaptchaToken = request.nextUrl.searchParams.get('reCaptchaToken')

  if (!reCaptchaToken) {
    return NextResponse.json(
      {
        errors: {
          message: 'Unauthorized - Customer token missing',
        },
      },
      { status: 401 },
    )
  }

  try {
    const captchaResponse = await fetch(
      'https://www.google.com/recaptcha/api/siteverify',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          secret: `${process.env.RECAPTCHA_V3_SECRET}`,
          response: reCaptchaToken,
        }),
      },
    ).then((res) => {
      return res.json()
    })

    if (!captchaResponse.success) {
      throw new Error(captchaResponse['error-codes'][0])
    }

    return NextResponse.json({ data: captchaResponse }, { status: 200 })
  } catch (e) {
    return NextResponse.json(
      {
        errors: e,
      },
      { status: 500 },
    )
  }
}
