import { useTranslations } from 'next-intl'

export default function ReCaptchaBrandingText() {
  const t = useTranslations('ReCaptchaBrandingText')

  return (
    <div>
      {t.rich('text', {
        privacyPolicy: (children) => (
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            className="underline"
          >
            {children}
          </a>
        ),
        termsOfService: (children) => (
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            className="underline"
          >
            {children}
          </a>
        ),
      })}
    </div>
  )
}
