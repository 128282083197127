'use client'
import React, { useState } from 'react'
import { useTranslations } from 'next-intl'

import { RequestItemModal } from './request-item-modal/request-item-modal'
import { Button } from '@/components/button'
import { useDisclosure } from '@/common/hooks/use-disclosure'

export type ModalState = 'form' | 'success' | 'error'

type CategoryProductsRequestModalProps = {
  id: string
}

export default function CategoryProductsRequestModal({
  id,
}: CategoryProductsRequestModalProps) {
  const requestItemModal = useDisclosure()
  const t = useTranslations('Category')
  const [modalState, setModalState] = useState<ModalState>('form')

  const handleOpenAddNoteModal = () => {
    setModalState('form')
    requestItemModal.onOpen()
  }

  return (
    <>
      <RequestItemModal
        modalState={modalState}
        setModalState={setModalState}
        isOpen={requestItemModal.isOpen}
        onClose={requestItemModal.onClose}
        id={parseInt(id)}
      />
      <Button
        variant="text"
        onClick={handleOpenAddNoteModal}
        className="h-[unset] whitespace-normal p-0 my-5"
      >
        {t('products.requestProduct.button.openModal')}
      </Button>
    </>
  )
}
