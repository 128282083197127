import { useTranslations } from 'next-intl'

import { Link } from '@/components/link/link'
import { CategoryFiltersCheckbox } from '../../category-types'
import { useCategoryFilters } from '@/providers/category-filters/use-category-filters'

type Props = {
  checkboxFilters?: CategoryFiltersCheckbox[] | null
}

const CategoryFiltersCheckboxes = ({ checkboxFilters }: Props) => {
  const { onFilterClick } = useCategoryFilters()
  const t = useTranslations('Category')

  const areCheckboxesEmpty = !checkboxFilters || checkboxFilters.length === 0

  if (areCheckboxesEmpty) {
    return null
  }

  return (
    <div className="flex flex-col md:flex-row border-b border-black py-4">
      <h3 className="font-bold basis-3/12 pb-2 md:pb-0">
        {t('filter.checkboxes').toUpperCase()}
      </h3>

      <div className="basis-9/12 text-xs text-gray-500 grid grid-cols-2 sm:grid-cols-3 grid-flow-row gap-4">
        {checkboxFilters?.map(
          ({ eventId, count, name, url, used }) =>
            url && (
              <Link
                key={name}
                className="leading-tight cursor-pointer flex items-start justify-start gap-1"
                href={url}
                onClick={() => onFilterClick(eventId)}
              >
                <div className="h-3 w-3 mt-0.5 border-2 border-gray-500 hover:border-secondary inline-block">
                  {used && <div className="bg-secondary w-1 h-1 m-0.5" />}
                </div>
                <div className="flex-1">
                  {name} ({count ?? 0})
                </div>
              </Link>
            ),
        )}
      </div>
    </div>
  )
}

export default CategoryFiltersCheckboxes
